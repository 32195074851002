var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "전력량계명",
                            name: "envWaterMstWattmeterName",
                          },
                          model: {
                            value: _vm.data.envWaterMstWattmeterName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.data,
                                "envWaterMstWattmeterName",
                                $$v
                              )
                            },
                            expression: "data.envWaterMstWattmeterName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "전력량계 관리번호",
                            name: "envWaterMstWattmeterNo",
                          },
                          model: {
                            value: _vm.data.envWaterMstWattmeterNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "envWaterMstWattmeterNo", $$v)
                            },
                            expression: "data.envWaterMstWattmeterNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "number",
                            label: "전력량계 배율",
                            name: "wattmeterMagnification",
                          },
                          model: {
                            value: _vm.data.wattmeterMagnification,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "wattmeterMagnification", $$v)
                            },
                            expression: "data.wattmeterMagnification",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-dept", {
                          attrs: {
                            type: "edit",
                            editable: _vm.editable,
                            label: "관리부서",
                            name: "deptCd",
                          },
                          model: {
                            value: _vm.data.deptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "deptCd", $$v)
                            },
                            expression: "data.deptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "사용여부",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }